<template>
    <div class="content" v-loading="loading">
        <div class="top">
            <div class="among">
                <div class="userInfo">
                    <div style="margin-right: 20px" @click="goHome">商城首页</div>
                    <div style="margin-right: 4px" v-if="$store.state.userInfo.userName">{{ $store.state.userInfo.userName
                    }}</div>
                    <span style="margin-right: 20px">你好,欢迎光临cnc-x商城</span>
                    <div style="margin-right: 20px" @click="shoppingCart">购物车</div>
                    <div style="margin-right: 20px" @click="myOrder">我的订单</div>
                    <div style="margin-right: 20px" @click="myCollect">收藏夹</div>
                    <div style="margin-right: 20px" @click="helpCenter">帮助中心</div>
                    <div style="margin-right: 20px" @click="logIn" v-if="!$store.state.userInfo.userId">登录</div>
                    <div style="margin-right: 20px" @click="logOut" v-else>退出</div>
                </div>
            </div>
        </div>
        <div class="bot">
            <div class="among">
                <div class="l">
                    <div class="img">
                        <img :src="mallStoreInfo.storeLog" alt="">
                    </div>
                    <div class="info_box">
                        <div class="top">
                            <div class="name">{{ mallStoreInfo.storeName }}</div>
                            <div class="bq">诚{{ mallStoreInfo.storeGrade }}</div>
                        </div>
                        <div class="btn">
                            
                            <div class="experience">
                                综合体验：<div class="rate" style="width: 90px;"><el-rate v-model="mallStoreInfo.mallStoreEvaluation.serviceValue"
                                        disabled-void-color="#C7C5C5" disabled :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                    </el-rate></div>
                            </div>
                            <div class="fens">粉丝数 {{ mallStoreInfo.mallStoreEvaluation.fansValue }}</div>
                            <!-- <div class="fuw">服务 {{ mallStoreInfo.mallStoreEvaluation.serviceValue.toFixed(1) }} {{
                                mallStoreInfo.mallStoreEvaluation.consultValue.toFixed(1) }} {{
        mallStoreInfo.mallStoreEvaluation.qualityValue.toFixed(1) }} {{
        mallStoreInfo.mallStoreEvaluation.logisticsValue.toFixed(1) }}</div> -->
                            <div class="guanz" v-if="isFans == false">
                                <img src="@/assets/icon/wgzdp.png" alt="">
                                <div class="text">关注店铺</div>
                            </div>
                            <div class="yguanz" v-else>
                                <img src="@/assets/icon/ygzdp.png" alt="">
                                <div class="text">已关注</div>
                            </div>
                            <div class="hover">
                                <div class="nameTitle">
                                    店铺：<span>{{ mallStoreInfo.storeName }}</span>
                                </div>
                                <div class="experience">
                                    综合体验：<div class="rate"><el-rate v-model="mallStoreInfo.mallStoreEvaluation.serviceValue"
                                            disabled-void-color="#C7C5C5" disabled
                                            :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                        </el-rate></div>
                                </div>
                                <div class="pj">
                                    <div class="so">
                                        <div class="text">咨询评价：<span>{{
                                            mallStoreInfo.mallStoreEvaluation.consultValue.toFixed(1) }}</span></div>
                                        <div class="text">服务评价：<span>{{
                                            mallStoreInfo.mallStoreEvaluation.serviceValue.toFixed(1) }}</span></div>
                                    </div>
                                    <div class="so">
                                        <div class="text">质量评价：<span>{{
                                            mallStoreInfo.mallStoreEvaluation.qualityValue.toFixed(1) }}</span></div>
                                        <div class="text">物流评价：<span>{{
                                            mallStoreInfo.mallStoreEvaluation.logisticsValue.toFixed(1) }}</span></div>
                                    </div>
                                </div>
                                <div class="btn_box">
                                    <div class="l" @click="storeClick">店铺逛逛</div>
                                    <div class="guanz" v-if="isFans == false" @click="followSubscription">
                                        <img src="@/assets/icon/wgzdp.png" alt="">
                                        <div class="text">关注店铺</div>
                                    </div>
                                    <div class="yguanz" v-else @click="followSubscription">
                                        <img src="@/assets/icon/ygzdp.png" alt="">
                                        <div class="text">已关注</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="r">
                    <div class="inp_content">
                        <div class="inp">
                            <input type="text" v-model="contentSou" placeholder="请输入搜索内容"  @keyup.enter="handleEnterKey" />
                            <div class="btn" @click="sousuo">搜全站</div>
                        </div>
                        <div class="text">
                            <!-- <span class="sousuoKey" @click="sousKeyClick(i)" v-for="(i, index) in keyVallueSous"
                                :key="index">{{
                                    i
                                }}</span> -->
                            <span class="sousuoKey cur" @click="sousKeyClick('立式加工中心', '010101')">立式加工中心</span>
                            <span class="sousuoKey cur" @click="sousKeyClick('卧式加工中心', '010102')">卧式加工中心</span>
                            <span class="sousuoKey cur" @click="sousKeyClick('立式车床', '010201')">立式车床</span>
                            <span class="sousuoKey cur" @click="sousKeyClick('液压剪板机', '020102')">液压剪板机</span>
                            <span class="sousuoKey cur" @click="sousKeyClick('光纤激光切割机', '030101')">光纤激光切割机</span>
                        </div>

                    </div>
                    <div class="gwc" @click="SearchOurStoreClick">
                        <div class="text">搜本店</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tabs">
            <div class="among">
                <div class="son" :class="[i.act ? 'tabsAct' : '']" @click="tabsClick(index)" v-for="(i, index) in tabArr"
                    :key="index">{{ i.name }}</div>
                <div v-if="cascaderShow" @mouseleave="mouseleave">
                    <el-cascader-panel @change="change" :options="storeClassificationList"
                        :props="{ expandTrigger: 'hover' }"></el-cascader-panel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getStoreFollowSubscription, getStoreInfo } from "@/utils/api/homeApi/index"
export default {
    name: "enterpriseHead",
    props: {
        menuShow: Boolean,
        tabArrShow: Number
    },
    data() {
        return {
            // 搜索框内容
            contentSou: '',
            tabArr: [
                {
                    name: "店铺首页",
                    act: false,
                },
                // {
                //     name: "商品分类",
                //     act: false,
                // },
                {
                    name: "全部商品",
                    act: false,
                },
                {
                    name: "公司信息",
                    act: false,
                },
                // {
                //     name: "联系我们",
                //     act: false,
                // }
            ],
            tabArrIndex: 0,
            cascaderShow: false,
            value: 4,
            // 商家店铺信息
            mallStoreInfo: {
                mallStoreEvaluation: {
                    consultValue: 0,
                    logisticsValue: 0,
                    qualityValue: 0,
                    serviceValue: 0,
                }
            },
            // 是否关注订阅了该店铺
            isFans: false,
            // 
            loading: true,
            // 店铺的商品分类
            storeClassificationList: [],
            // 店铺id
            // ids:''
            // 搜索历史
            keyVallueSous: []
        }

    },
    watch: {
        '$store.state.commodityInfo'(newVal, oldVal) {
            // this.getStore(this.$store.state.commodityInfo.mallStore.storeId)
            this.getStore(localStorage.getItem('storeId'))
        },
        '$store.state.mallStoreInfo'(newVal, oldVal) {
            this.mallStoreInfo = newVal
            this.loading = false
        },
        '$store.state.isFans'(newVal, oldVal) {
            this.isFans = newVal
        },
        '$store.state.storeClassificationList'(newVal, oldVal) {
            this.storeClassificationList = this.convertData(newVal)
        },
        '$route.path'(newPath, oldPath) {
            if (this.$route.query.tabArrIndex) {
                this.tabArr.map(p => p.act = false)
                this.tabArr[this.$route.query.tabArrIndex].act = true
            }
        }
    },
    beforeDestroy() {
        // 在 beforeDestroy 钩子函数中删除事件监听器
        document.removeEventListener('click', this.handleClick);
    },
    mounted() {
        this.$store.commit('getUserInfoData')
        // 从本地缓存中取出字符串并转换为数组
        this.keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous'));
        console.log(this.keyVallueSous)
        if (localStorage.getItem('storeId')) {
            this.getStore(localStorage.getItem('storeId'))
        }
        // this.ids = this.$route.query.ids;
        // 在 mounted 钩子函数中添加事件监听器
        document.addEventListener('click', this.handleClick);
        this.tabArrIndex = this.$route.query.tabArrIndex || 0
        console.log(this.tabArrIndex, 'this.tabArrIndex')
        this.tabArr.map(p => p.act = false)
        this.tabArr[this.tabArrIndex].act = true
    },
    methods: {
        goHome() {
            this.$router.push("/hoveContent?actNum=0")
        },
        storeClick() {
            window.open(`#/enterpriseHome/enterpriseContent?ids=${localStorage.getItem('storeId')}`)
        },
        logOut() {
            this.$confirm('确认退出登录吗?', '退出', {
                confirmButtonText: '退出',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                localStorage.removeItem('token');
                localStorage.removeItem('historyRoute');
                this.$router.push('/login')
                this.$message.success('请重新登录')
            })
        },
        logIn() {
            localStorage.setItem('historyRoute', this.$route.fullPath);
            this.$router.push('/login')
        },
        // 返回首页
        shouYe() {
            this.$router.push('/hoveContent?actNum=0')
        },
        // 跳转到我的订单页面
        myOrder() {
            window.open('#/myIndex/myOrder?actMyNum=0', '_blank');
        },
        // 跳转到帮助中心页面
        helpCenter() {
            window.open('#/helpCenter', '_blank');
        },
        // 跳转到我的收藏
        myCollect() {
            window.open('#/myIndex/myCollect?actMyNum=5', '_blank');
        },
        // 跳转购物车
        shoppingCart() {
            window.open('#/myShoppingCart', '_blank');
        },
        // 点击历史搜索
        sousKeyClick(typeName, id) {
            window.open(`#/search?id=${id}&typeName=${typeName}`, '_blank')
            return
            this.contentSou = i
            let keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous')) || [];
            let index = keyVallueSous.indexOf(this.contentSou)
            if (index !== -1) {
                keyVallueSous.splice(index, 1);
                keyVallueSous.unshift(this.contentSou);
            } else {
                keyVallueSous.unshift(this.contentSou)
            }
            if (keyVallueSous.length > 5) {
                keyVallueSous.splice(5, keyVallueSous.length - 5);
            }
            // 将数组转换为字符串并存入本地缓存中
            localStorage.setItem('keyVallueSous', JSON.stringify(keyVallueSous));
            window.open(`#/search2?keyWords=${i}`, '_blank');
        },
        handleEnterKey(){
            this.sousuo()
        },
        // 点击搜索全站
        sousuo() {
            // if (!this.contentSou) return this.$message({
            //     message: "请输入关键词"
            // })
            let keyVallueSous = JSON.parse(localStorage.getItem('keyVallueSous')) || [];
            let index = keyVallueSous.indexOf(this.contentSou)
            if (index !== -1) {
                keyVallueSous.splice(index, 1);
                keyVallueSous.unshift(this.contentSou);
            } else {
                keyVallueSous.unshift(this.contentSou)
            }
            if (keyVallueSous.length > 5) {
                keyVallueSous.splice(5, keyVallueSous.length - 5);
            }
            // 将数组转换为字符串并存入本地缓存中
            localStorage.setItem('keyVallueSous', JSON.stringify(keyVallueSous));
            window.open(`#/search2?keyWords=${this.contentSou}`, '_blank');
        },
        // 搜索本店
        SearchOurStoreClick() {
            this.tabArr.map(p => p.act = false)
            this.tabArr[1].act = true
            console.log(this.contentSou, 123)
            this.$store.commit('mallStoreTitleSouS', this.contentSou)
            // window.open(`#/enterpriseHome/enterpriseCommodity?tabArrIndex=1&titleStore=${this.contentSou}`, '_blank');
            this.$router.push(`/enterpriseHome/enterpriseCommodity?tabArrIndex=1&titleStore=${this.contentSou}`)
        },
        // 店铺关注订阅与取消
        async followSubscription() {
            if (this.isFans) {
                const res = await getStoreFollowSubscription({ type: 'cancel', ids: this.mallStoreInfo.storeId })
                if (res.data) {
                    this.$message({
                        message: "已取消关注"
                    })
                    this.isFans = false
                }
            } else {
                const res = await getStoreFollowSubscription({ type: 'fans', ids: this.mallStoreInfo.storeId })
                if (res.data) {
                    this.$message({
                        message: "关注成功",
                        type: 'success',
                    })
                    this.isFans = true
                }
            }
            this.$store.commit("getIsFans", this.isFans)
        },
        // 获取店铺信息
        async getStore(ids) {
            const res = await getStoreInfo(ids)
            if (res.statusCode == 8201) {
                this.isFans = res.data.fans
                this.mallStoreInfo = res.data
                this.loading = false
                this.$store.commit("getStore", res.data)
                this.$store.commit("getIsFans", res.data.fans)
                console.log(res.data, 'res.data')
            }
        },
        handleClick(event) {
            // 处理点击事件
            if (event.target.innerText == '商品分类') return
            this.cascaderShow = false
        },
        // tab点击事件
        tabsClick(index) {
            this.tabArr.map((t) => (t.act = false));
            this.tabArr[index].act = true;
            if (index == 0) return this.$router.push(`/enterpriseHome/enterpriseContent?tabArrIndex=0&ids=${localStorage.getItem('storeId')}`)
            // if (index == 1) return this.cascaderShow = !this.cascaderShow
            if (index == 1) return this.$router.push("/enterpriseHome/enterpriseCommodity?tabArrIndex=1")
            if (index == 2) return this.$router.push("/enterpriseHome/enterpriseInformation?tabArrIndex=2")
        },
        // 联级点击事件
        change(value) {
            console.log(value);
            this.cascaderShow = false
        },
        // 鼠标移出事件
        mouseleave() {
            this.cascaderShow = false
        },
        convertData(data) {
            return data.map(item => {
                const newItem = { label: item.typeName, value: item.id };
                if (item.children && item.children.length) {
                    newItem.children = this.convertData(item.children);
                }
                return newItem;
            });
        }
    }
}
</script>

<style lang="less" scoped>
.content {
    width: 100%;
    height: 180px;
    min-width: 1200px;

    >.top {
        width: 100%;
        height: 30px;
        background-color: #f5f5f5;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;

        >.among {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: block !important;

            >.userInfo {
                width: auto;
                float: right;
                line-height: 30px;
                font-size: 12px;
                color: #333333;
                display: flex;

                >div {
                    cursor: pointer;
                    transition: all 0.3s;
                }

                >div:hover {
                    color: #ff4242;
                    transition: all 0.3s;
                }
            }
        }
    }

    >.bot {
        width: 100%;
        height: 105px;
        display: flex;
        background-color: #fff;

        >.among {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding: 25px 30px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;

            >.l {
                width: auto;
                height: auto;
                display: flex;

                >.img {
                    width: 75px;
                    height: 75px;
                    display: flex;
                    align-items: center;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                >.info_box {
                    width: auto;
                    margin: 5px 10px;

                    >.top {
                        display: flex;
                        align-items: center;

                        >.name {
                            font-size: 20px;
                            color: #000;
                        }

                        >.bq {
                            width: 28px;
                            height: 16px;
                            border-radius: 25px;
                            background-color: #FF4242;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;
                            line-height: 16px;
                            margin-left: 8px;
                        }
                    }

                    >.btn {
                        width: 310px;
                        height: 20px;
                        margin-top: 15px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;

                        >.fens,
                        .fuw {
                            width: auto;
                            padding: 1px 8px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            border: 1px solid #FF7D7D;
                            font-size: 12px;
                            color: #FF4242;
                        }

                        >.experience {
                            font-size: 12px;
                            color: #666;
                            display: flex;
                            margin-top: 8px;
                            padding-bottom: 10px;
                            box-sizing: border-box;
                            align-items: center;
                        }

                        >.guanz {
                            cursor: pointer;
                            width: auto;
                            padding: 1px 8px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            border: 1px solid #FF7D7D;
                            background-color: #FF4242;
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            color: #fff;

                            >img {
                                width: 13px;
                                margin-right: 3px;
                            }
                        }

                        >.yguanz {
                            cursor: pointer;
                            width: auto;
                            padding: 1px 8px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            border: 1px solid #FF7D7D;
                            background-color: #fff;
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            color: #FF4242;

                            >img {
                                width: 13px;
                                margin-right: 3px;
                            }
                        }

                        >.hover {
                            position: absolute;
                            top: 30px;
                            width: 350px;
                            height: 210px;
                            border-radius: 8px;
                            background-color: #fff;
                            padding: 16px 23px;
                            box-sizing: border-box;
                            transition: all .3s;
                            border: 1px solid #E0E0E0;
                            z-index: -1;
                            opacity: 0;

                            >.nameTitle {
                                font-size: 12px;
                                color: #666;
                                padding-left: 5px;
                                box-sizing: border-box;

                                >span {
                                    font-size: 16px;
                                    color: #000;
                                    font-weight: bold;
                                    margin-left: 10px;
                                }
                            }

                            >.experience {
                                font-size: 12px;
                                color: #666;
                                display: flex;
                                margin-top: 8px;
                                border-bottom: 1px dashed rgba(0, 0, 0, .2);
                                padding-bottom: 10px;
                                padding-left: 5px;
                                box-sizing: border-box;
                            }

                            >.pj {
                                width: 100%;
                                margin-top: 15px;
                                border-bottom: 1px dashed rgba(0, 0, 0, .2);
                                padding-bottom: 10px;
                                padding-left: 5px;
                                box-sizing: border-box;

                                >.so {
                                    width: 100%;
                                    display: flex;
                                    margin-bottom: 10px;

                                    >.text {
                                        font-size: 12px;
                                        color: #666666;
                                        margin-right: 40px;

                                        >span {
                                            color: #000;
                                            margin-left: 4px;
                                        }
                                    }
                                }
                            }

                            >.btn_box {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                margin-top: 15px;
                                padding: 0 20px;
                                box-sizing: border-box;

                                >.l {
                                    width: 80px;
                                    height: 26px;
                                    border-radius: 4px;
                                    border: 1px solid #999999;
                                    text-align: center;
                                    line-height: 26px;
                                    color: #666666;
                                    font-size: 12px;
                                    cursor: pointer;
                                }

                                >.guanz {
                                    cursor: pointer;
                                    width: 90px;
                                    height: 28px;
                                    padding: 1px 8px;
                                    box-sizing: border-box;
                                    border-radius: 4px;
                                    border: 1px solid #FF7D7D;
                                    background-color: #FF4242;
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    color: #fff;

                                    >img {
                                        width: 13px;
                                        margin-right: 3px;
                                    }
                                }

                                >.yguanz {
                                    cursor: pointer;
                                    width: 90px;
                                    height: 28px;
                                    padding: 1px 8px;
                                    box-sizing: border-box;
                                    border-radius: 4px;
                                    border: 1px solid #FF7D7D;
                                    background-color: #fff;
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    color: #FF4242;

                                    >img {
                                        width: 13px;
                                        margin-right: 3px;
                                    }
                                }
                            }
                        }
                    }

                    >.btn:hover {
                        >.hover {
                            z-index: 999;
                            opacity: 1;
                            transition: all .3s;
                        }
                    }
                }
            }

            >.r {
                display: flex;

                >.inp_content {
                    width: 420px;
                    height: 36px;
                    border-radius: 4px;
                    border: 2px solid #ff4242;
                    box-sizing: border-box;

                    .inp {
                        width: 100%;
                        height: 100%;
                        display: flex;

                        >input {
                            width: 340px;
                            height: 30px;
                            background-color: #f9f9f9;
                            border: 0px;
                            border-radius: 4px 0 0 4px;
                            padding-left: 10px;
                            outline: none;
                        }

                        >.btn {
                            width: 80px;
                            background-color: #ff4242;
                            text-align: center;
                            line-height: 32px;
                            color: #ffffff;
                            cursor: pointer;
                        }
                    }

                    >.text {
                        width: 100%;
                        font-size: 12px;
                        color: #666;
                        margin-top: 6px;

                        >span {
                            margin-right: 16px;
                        }
                    }
                }

                >.gwc {
                    width: 60px;
                    height: 36px;
                    margin-left: 5px;
                    border-radius: 4px;
                    border: 1px solid #e0e0e0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    position: relative;


                    >.text {
                        font-size: 12px;
                        color: #ff4242;
                    }

                }
            }
        }
    }

    >.tabs {
        width: 100%;
        height: 36px;
        background-color: #FF4242;
        display: flex;
        margin-top: 0 10px;
        user-select: none;

        >.among {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            display: flex;
            position: relative;
            align-items: center;

            >.son {
                width: 66px;
                height: 36px;
                text-align: center;
                line-height: 36px;
                margin-left: 10px;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                transition: all .3s;
            }
        }
    }

    /deep/ .el-cascader-panel.is-bordered {
        background-color: #fff !important;
        z-index: 9999;
        position: absolute;
        left: 74px;
        top: 36px;
    }
}

.sousuoKey {
    cursor: pointer;
    transition: all .3s;
}

.sousuoKey:hover {
    color: red;
    transition: all .3s;
}

.tabsAct {
    background-color: #FF584E;
    transition: all .3s;
}

/deep/ .el-rate__icon {
    margin-right: 0 !important;
}
</style>