<template>
    <div class="home">
        <!-- 头部 -->
        <enterpriseHead :tabArrShow="tabArrShow"></enterpriseHead>


        <!-- 首页主体内容 -->
        <router-view />

        <!-- 底部 -->
        <bottomContent></bottomContent>

    </div>
</template>

<script>
import enterpriseHead from "@/components/enterpriseHead.vue";
import bottomContent from "@/components/bottomContent.vue";
export default {
    name: 'enterpriseHome',
    components: {
        enterpriseHead,
        bottomContent
    },
    data() {
        return {
            tabArrShow: 0,
            swiperOption: {
                slidesPerView: 1,
                // 设置分页器
                pagination: {
                    el: ".swiper-pagination",
                    // 设置点击可切换
                    clickable: true,
                    dynamicBullets: true,
                },
                // 设置自动轮播
                autoplay: {
                    delay: 3000, // 5秒切换一次
                },
                // 设置轮播可循环
                loop: true,
            },
            value: 4
        };
    },
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    >.topHead {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;

        >.l {
            width: 800px;
            height: 368px;
            border-radius: 4px;
            overflow: hidden;
        }

        >.r {
            width: 380px;
            height: 368px;
            border-radius: 4px;
            background-color: #fff;
            padding: 25px;
            box-sizing: border-box;

            >.name_content {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;

                >.tilt {
                    font-size: 12px;
                    color: #666;
                    margin-top: 6px;
                }

                >.name {
                    font-size: 20px;
                    color: #000;
                    font-weight: bold;
                }

                >.rate {
                    margin-top: 6px;
                }
            }

            >.pj_box {
                width: 100%;
                display: flex;
                margin-bottom: 10px;

                >.son {
                    font-size: 12px;
                    color: #666;
                    margin-right: 50px;

                    >span {
                        color: #000;
                    }
                }
            }

            >.tabs {
                width: 100%;
                height: auto;
                display: flex;
                flex-wrap: wrap;

                >.son {
                    width: auto;
                    padding: 3px 18px;
                    box-sizing: border-box;
                    background-color: #F2F2F2;
                    border-radius: 15px;
                    text-align: center;
                    font-size: 14px;
                    color: #333;
                    margin-right: 10px;
                    margin-bottom: 13px;
                }
            }
        }
    }
}

/deep/ .swiper-pagination-bullet-active {
    background-color: #ff4242 !important;
}
</style>